import { EsgDmaValueChainEntityInSchema } from 'src/Esg/Dmav2/types';

export const entityColors = [
  '#E9BC46',
  '#A1DF76',
  '#CEDF9D',
  '#B6D9FC',
  '#A1E4C8',
  '#97E3FB',
  '#FAAD94',
  '#F5CF96',
  '#E4B78D',
  '#E0BFE3',
];

export function getNameToColorMapping(names: string[]): {
  [key: string]: string;
} {
  return names.reduce(
    (acc, name, index) => {
      acc[name] = entityColors[index % entityColors.length];
      return acc;
    },
    {} as { [key: string]: string }
  );
}

export function getUniqueEntityNames(
  entitiesData: EsgDmaValueChainEntityInSchema[]
): string[] {
  return Array.from(
    new Set(
      entitiesData
        .map((entity) => entity.name)
        .filter((name): name is string => name !== undefined)
    )
  );
}
