import React from 'react';
import { EsgDmaValueChainEntityInSchema } from 'src/Esg/Dmav2/types';
import { useLanguage } from 'src/common/hooks';
import { Typography } from '@mui/material';
import ValueChainTable from './ValueChainTable';
import { getNameToColorMapping, getUniqueEntityNames } from '../../utils';
import TextWithTooltip from 'src/components/TextWithTooltip';

interface Props {
  entitiesData: EsgDmaValueChainEntityInSchema[];
}

export default function ValueChainEntitiesSummary({ entitiesData }: Props) {
  const { t } = useLanguage({ keyPrefix: 'esg:dma.step1.valueChain' });

  const uniqueNames = getUniqueEntityNames(entitiesData);
  const nameToColor = getNameToColorMapping(uniqueNames);

  const renderCellContent = (
    entity: EsgDmaValueChainEntityInSchema | undefined,
    nameToColor: { [key: string]: string }
  ) => {
    if (!entity) return null;
    return (
      <Typography
        variant="subtitle2"
        sx={{
          backgroundColor: entity.name
            ? nameToColor[entity.name]
            : 'transparent',
          width: 'fit-content',
          padding: '2px 8px',
          margin: '0 auto',
          borderRadius: '6px',
        }}
      >
        <TextWithTooltip minLength={26} text={entity.name ?? ''} />
      </Typography>
    );
  };

  return (
    <ValueChainTable
      entitiesData={entitiesData}
      renderCellContent={renderCellContent}
      tableTitle={t('valueChain')}
      nameToColor={nameToColor}
    />
  );
}
