import React from 'react';
import Loader, { Container } from '../components/Loader';
import SomethingWentWrong from '../components/SomethingWentWrong';
import { useAppSelector } from '../redux-file/hooks';
import { client, handleToastError } from '../utils/api-client';
import { setError } from '../redux-file/platform/slice';
import { AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { auth } from '../utils';

export default function AuthorizedAccessProvider(props: any) {
  const { error, isAuthenticated } = useAppSelector((state) => state.platform);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // set token to axios client
  React.useEffect(() => {
    const interceptor = client.interceptors.request.use(async (config) => {
      if (config.url?.includes('web/auth/')) return config; // do not add token to auth requests
      const token = await auth.ensureAccessToken();
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    return () => client.interceptors.request.eject(interceptor);
  }, []);

  // setup global error handler
  // managed: 401, 403, 502
  React.useEffect(() => {
    const unathorizedInterceptor = client.interceptors.response.use(
      (res: AxiosResponse<any, any>) => res,
      (error) => {
        if (error?.response?.status === 401) {
          navigate('/logout');
        } else if (error?.response?.status === 403) {
          if (error?.response?.data?.detail?.includes('consents')) return; // this is expected error status code so ignore it
          if (error?.response?.data?.detail?.includes('Demo access')) {
            navigate('/demo-access-expired');
          } else {
            navigate('/403');
          }
        } else if (error?.response?.status === 502)
          dispatch(setError({ msg: 'Please try again later' }));
        handleToastError(error);
        throw error;
      }
    );
    return () => client.interceptors.response.eject(unathorizedInterceptor);
  }, []);

  if (error)
    return (
      <Container>
        <SomethingWentWrong errorMessege={error.msg} />
      </Container>
    );

  if (isAuthenticated === undefined) return <Loader />;
  if (isAuthenticated === false) return <Navigate to="/login" />;

  return props.children;
}
