import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';

import { useLanguage } from 'src/common/hooks';

import { StyledFlex } from 'src/components/StyledFlex';
import ValueChainEntities from './ValueChainEntities';
import ValueChainEntitiesSummary from './components/ValueChainEntitiesSummary';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { useEsgReports } from 'src/Esg/hooks';
import { useEsgDmas, useEsgDmaValueChainEntities } from '../hooks';
import EsgValueChainToggle, { EsgValueChainViews } from './components/Toggle';
import ValueChainStakeholderTypesSummary from './components/ValueChainStakeholderTypesSummary';

export default function Step1() {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const { reportId } = useParams();

  const [view, setView] = useState<EsgValueChainViews>(
    EsgValueChainViews.ENTITIES
  );

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: EsgValueChainViews
  ) => {
    if (newValue !== null) {
      setView(newValue);
    }
  };

  const { _instance: esgReport } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma } = useEsgDmas(
    { id: esgReport?.esg_dma_id },
    { enabled: !!esgReport }
  );
  const {
    _data: entitiesData,
    isError,
    isLoading,
  } = useEsgDmaValueChainEntities({ esg_dma_id: dma?.id }, { enabled: !!dma });

  if (isError) return <SomethingWentWrong />;
  if (isLoading) return <PageLoading />;

  return (
    <Box sx={{ margin: '0 40px 20px 40px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledFlex sx={{ justifyContent: 'flex-start' }}>
          <IconButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeft fontSize="large" sx={{ color: '#38414f' }} />
          </IconButton>
          <Typography variant="h1">
            {t('esg:dma.step1.valueChain.title')}
          </Typography>
        </StyledFlex>

        <EsgValueChainToggle value={view} onChange={handleToggleChange} />
      </Box>

      {view === EsgValueChainViews.ENTITIES ? (
        <ValueChainEntities entitiesData={entitiesData} />
      ) : (
        <>
          <ValueChainEntitiesSummary entitiesData={entitiesData} />
          <ValueChainStakeholderTypesSummary entitiesData={entitiesData} />
        </>
      )}
    </Box>
  );
}
