import React from 'react';
import { ObjectModal as Props } from './index.types';
import {
  Alert,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'src/Lca/components/Button';

import {
  StyledTitle,
  StyledSectionName,
  StyledTextField,
  StyledRow,
  StyledLine,
  StyledSelect,
} from './index.styles';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import { AxiosError } from 'axios';
import {
  EcoinventActivity,
  LCAProcess,
  LcaObjectCustomFactor,
} from '../index.types';
import { parseRequestErrors } from 'src/utils/helpers';
import ActivitiesSearchField from '../components/ActivitiesSearchField';
import {
  useEcoinventProLicenceValid,
  useLcaDiagram,
  useLcaProduct,
} from 'src/Lca/hooks';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { toast } from 'react-toastify';
import { useLcaObjects } from '../hooks';
import CustomFactorsFields from './CustomFactorsFields';
import { useLanguage } from 'src/common/hooks';

export default function ObjectModal({
  productId,
  open,
  onClose: _onClose,
  object,
  subcategory,
}: Props) {
  const isNew = !object?.id;
  const url = `/web/lca/objects/${productId}`;

  type InputType = string | undefined;
  type InputEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const [categoryKey, setCategoryKey] = React.useState('');

  const { product } = useLcaProduct(productId);
  const { stagesAsOptions } = useLcaDiagram(productId);
  const { isEcoinventLicenceValid } = useEcoinventProLicenceValid();
  const { t } = useLanguage();
  const ecoinventVersion = product?.ecoinvent_version;

  React.useEffect(() => {
    if (object?.category_key) setCategoryKey(object?.category_key);
    else if (object?.category_key === null) setCategoryKey('uncategorized');
  }, [object]);

  const getProcesses = useQuery(`lca-product-${productId}-processes`, () =>
    client
      .get(`/web/lca/processes/${productId}`)
      .then((response) => response.data)
  );

  const [name, setName] = React.useState<InputType>('');
  const [ecoinventActivity, setEcoinventActivity] = React.useState<
    number | null
  >(null);
  const [amount, setAmount] = React.useState<InputType>('');
  const [unit, setUnit] = React.useState<InputType>('');
  const [comment, setComment] = React.useState<InputType>('');
  const [stage, setStage] = React.useState<InputType>('');
  const [process, setProcess] = React.useState<InputType>('');
  const [errors, setErrors] = React.useState<{ [key: string]: string }>({});
  const [useCustomFactors, setUseCustomFactors] =
    React.useState<boolean>(false);
  const [customFactorValues, setCustomFactorValues] = React.useState<
    LcaObjectCustomFactor[]
  >([]);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);

  const handleNameChange = (e: InputEvent) => setName(e.target.value);
  const handleAmountChange = (e: InputEvent) => setAmount(e.target.value);
  const handleUnitChange = () => {};
  const handleCommentChange = (e: InputEvent) => setComment(e.target.value);
  const handleUseCustomFactorsChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (object?.custom_factors && object?.custom_factors.length > 0) {
      return;
    }
    setUseCustomFactors(e.target.checked);
  };

  const handleProcessChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value: newProcess } = e.target;
    setProcess(newProcess as string);
    const process = processOptions?.find(
      (process: LCAProcess) => String(process.id) === newProcess
    );
    if (process) setStage(process.stage);
  };

  const handleActivitySearchChange = (activity: EcoinventActivity | null) => {
    setEcoinventActivity(activity?.id ?? null);
    setUnit(activity?.reference_product_unit ?? '');
  };

  const handleStageChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value: newStage } = e.target;
    const isProcessInStage = !!processOptions.filter(
      (process: LCAProcess) => process.id === process
    ).length;
    if (!isProcessInStage) setProcess('');
    setStage(newStage as string);
  };

  const handleCustomFactorChange = (e: InputEvent, id: number) => {
    const { value } = e.target;
    const numericValue = parseFloat(value);
    const factor = Number.isFinite(numericValue) ? numericValue : null;
    setCustomFactorValues((prevValues) => {
      // Filter out the previous value for the given id and add the new one
      return prevValues
        .filter((item) => item.area_id !== id)
        .concat({ area_id: id, factor });
    });
  };

  const onClose = (e?: any) => {
    e?.preventDefault();
    setName('');
    setAmount('');
    setUnit('');
    setComment('');
    setStage('');
    setProcess('');
    setEcoinventActivity(null);
    setErrors({});
    _onClose();
    setCustomFactorValues([]);
  };

  const processOptions = getProcesses.data?.filter(
    (process: LCAProcess) => !stage || process.stage === stage
  );

  React.useEffect(() => {
    if (!isNew) {
      setName(String(object.name));
      setAmount(String(object.amount));
      setComment(String(object?.comment));
      setStage(String(object.stage));
      setProcess(String(object?.parent_process || ''));
      setEcoinventActivity(object?.ecoinvent_activity?.id || null);
      setUnit(object?.ecoinvent_activity?.reference_product_unit || '');
      setCustomFactorValues(object?.custom_factors || []);
      if (object?.custom_factors && object?.custom_factors.length > 0) {
        setUseCustomFactors(true);
      }
    }
  }, []);

  const queryClient = useQueryClient();

  const requestActionshandlers = {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['lca-diagram'],
      });
      setErrors({});
      onClose();
    },
    onError: (error: AxiosError) =>
      setErrors(parseRequestErrors(error?.response?.data)),
  };

  const postObject = useMutation(
    (data: { [key: string]: any }) => client.post(url, data),
    requestActionshandlers
  );

  const putObject = useMutation(
    (data: { [key: string]: any }) => client.put(`${url}/${object?.id}`, data),
    requestActionshandlers
  );
  const deleteObject = useMutation(
    () => client.delete(`${url}/${object?.id}`),
    requestActionshandlers
  );

  const handleSubmit = () => {
    const data = {
      name,
      amount,
      comment,
      stage,
      parent_process_id: process || undefined,
      product_id: productId,
      subcategory_id: subcategory?.id,
      ecoinvent_activity_id: ecoinventActivity,
      custom_factor_values: customFactorValues,
    };
    if (isNew) {
      postObject.mutate(data);
    } else {
      putObject.mutate(data);
    }
  };

  const handleDeleteObject = () => {
    setDeleteConfirmationOpen(false);
    deleteObject.mutateAsync().then(() => {
      toast.success('Object removed');
    });
  };
  const { requestCopy } = useLcaObjects();
  const handleCopyClick = () => !!object?.id && requestCopy.mutate(object.id);

  const saveButtonText = isNew ? 'Create' : 'Save changes';

  const isLoading = getProcesses.isLoading || postObject.isLoading;

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg">
      <IconButton
        sx={{ position: 'absolute', top: 12, right: 10 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <StyledTitle>{subcategory?.name || 'Uncategorized'}</StyledTitle>

      <DialogContent sx={{ width: 900 }}>
        <StyledSectionName>Material details</StyledSectionName>
        <StyledRow>
          <StyledTextField
            fullWidth
            name="name"
            value={name}
            onChange={handleNameChange}
            disabled={isLoading}
            error={!!errors.name}
            helperText={errors.name}
            label="Name of the building or a construction material"
            categorykey={categoryKey}
          />
        </StyledRow>
        <StyledRow sx={{ gap: '24px' }}>
          <ActivitiesSearchField
            value={ecoinventActivity}
            onChange={handleActivitySearchChange}
            object={object}
            ecoinventVersion={ecoinventVersion}
          />
          <Button
            color="default"
            onClick={() =>
              window.open(
                `https://ecoquery.ecoinvent.org/${ecoinventVersion}/cutoff/search`,
                '_blank'
              )
            }
            isSecondary
          >
            Open Ecoinvent Library
          </Button>
        </StyledRow>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Ecoinvent database version: {ecoinventVersion}
        </Typography>
        {!isEcoinventLicenceValid && (
          <Alert severity="info">{t('lca.provideCustomFactors')}</Alert>
        )}
        <StyledRow style={{ justifyContent: 'start' }}>
          <Checkbox
            checked={useCustomFactors}
            onChange={handleUseCustomFactorsChange}
          />
          <StyledSectionName>Use custom factors</StyledSectionName>
        </StyledRow>
        {useCustomFactors && (
          <>
            <CustomFactorsFields
              values={customFactorValues}
              handleChange={handleCustomFactorChange}
              errors={errors}
              isLoading={isLoading}
            />
            <StyledLine />
          </>
        )}

        <StyledRow>
          <StyledTextField
            fullWidth
            name="amount"
            type="number"
            value={amount}
            onChange={handleAmountChange}
            disabled={isLoading}
            error={!!errors.amount}
            helperText={errors.amount}
            label="Amount"
            categorykey={categoryKey}
          />
          <div style={{ width: 20 }} />
          <StyledTextField
            fullWidth
            name="unit"
            value={unit}
            onChange={handleUnitChange}
            disabled
            error={!!errors.unit}
            helperText={errors.unit}
            label="Unit"
            categorykey={categoryKey}
          />
        </StyledRow>

        <StyledRow>
          <StyledTextField
            fullWidth
            multiline
            minRows={2}
            name="comment"
            value={comment}
            onChange={handleCommentChange}
            disabled={isLoading}
            error={!!errors.comment}
            helperText={errors.comment}
            label="Comment (optional)"
            categorykey={categoryKey}
          />
        </StyledRow>

        <StyledLine />

        <StyledSectionName>Life cycle phases</StyledSectionName>
        <StyledRow>
          <StyledSelect
            fullWidth
            name="stage"
            value={stage}
            onChange={handleStageChange}
            disabled={isLoading}
            error={!!errors.stage}
            label="Life cycle phase"
            categorykey={categoryKey}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {stagesAsOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </StyledSelect>
          <div style={{ width: 20 }} />
          <StyledSelect
            fullWidth
            name="process"
            value={process}
            onChange={handleProcessChange}
            disabled={isLoading}
            error={errors.parent_process_id}
            label="Add to the process (optional)"
            categorykey={categoryKey}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {processOptions?.map((process: LCAProcess) => (
              <MenuItem key={process.id} value={process.id}>
                {process.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledRow>
        <br />

        {/* ACTION BUTTONS */}

        <StyledRow>
          {!!isNew && (
            <Button color="default" onClick={onClose} isSecondary>
              cancel
            </Button>
          )}

          {!isNew && (
            <StyledRow style={{ padding: 0, justifyContent: 'flex-start' }}>
              <Button
                color="default"
                isSecondary
                onClick={() => setDeleteConfirmationOpen(true)}
              >
                Remove
              </Button>
              <div style={{ width: 10 }} />
              <Button color="default" onClick={handleCopyClick} isSecondary>
                Copy
              </Button>
              <div style={{ width: 10 }} />
              <Button color="default" onClick={onClose} isSecondary>
                Hide
              </Button>
            </StyledRow>
          )}

          <Button color={String(categoryKey)} onClick={handleSubmit}>
            {saveButtonText}
          </Button>
        </StyledRow>
      </DialogContent>
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={handleDeleteObject}
        textKey="If you click 'Remove', selected object will be removed from the flowchart."
        titleKey="Are you sure you want to remove this object?"
      />
    </Dialog>
  );
}
