import { Box, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function DemoAccessExpired() {
  const { t } = useTranslation(undefined, { keyPrefix: 'forbidden' });
  return (
    <Box sx={{ width: '100%', mt: '20vh', textAlign: 'center' }}>
      <Typography variant="h4">{t('demoAccessExpired.title')}</Typography>
      <br />
      <Typography>{t('demoAccessExpired.subtitle')}</Typography>
    </Box>
  );
}
