import React from 'react';
import Container from '../components/Container';
import assets from '../assets';
import Title from '../components/Title';

import { useTranslation } from 'react-i18next';
import { useEsgStakeholderSurveyMetadata } from '../../hooks';
import FormattedSurvey from '../components/FormattedSurvey';
import OrganizationalLogo from 'src/components/OrganizationalLogo';
import { client } from 'src/utils/api-client';
import { EsgStakeholderCreateSurveyOutSchema } from '../../types';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { SESSION_SURVEY_TOKEN_KEY } from '../../utils';

const createEsgStakeholderSurvey = () => {
  const url = `/web/esg/v3/stakeholders-survey`;
  return client.post<EsgStakeholderCreateSurveyOutSchema>(url);
};

export default function StakeholderSurveyIntro() {
  const { t } = useTranslation();

  // Get the survey token from the session storage if it exists
  const stakeholderSurveyToken = sessionStorage.getItem(
    SESSION_SURVEY_TOKEN_KEY
  );
  // Redirect to the end page if the survey already exists
  if (stakeholderSurveyToken)
    window.location.href = `/stakeholders/${stakeholderSurveyToken}/end`;

  // Create a new stakeholder survey when the user clicks the next button
  const nextButtonHandler = () => {
    createEsgStakeholderSurvey().then((res) => {
      const { token } = res.data;
      sessionStorage.setItem(SESSION_SURVEY_TOKEN_KEY, token);
      window.location.href = `/stakeholders/${token}/survey`;
    });
  };

  const { metadata, isError } = useEsgStakeholderSurveyMetadata();

  if (isError) return <SomethingWentWrong />;
  return (
    <Container
      header={<img src={assets.intro.image} />}
      nextLink={{
        onClick: nextButtonHandler,
        label: 'esg:stakeholderSurvey.next',
      }}
    >
      <div>
        <Title
          title={t('esg:stakeholderSurvey.title')}
          period={metadata.report_period}
        />
        <FormattedSurvey
          text={
            metadata.intro_text ||
            t('esg:stakeholderSurvey.intro.message', {
              organization_name: metadata.organization_name,
            })
          }
        />
        <OrganizationalLogo logo={metadata.logo} />
      </div>
    </Container>
  );
}
