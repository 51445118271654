import React from 'react';
import Container from '../components/Container';
import { useTranslation } from 'react-i18next';

import assets from '../assets';
import FormattedSurvey from '../components/FormattedSurvey';
import Title from '../components/Title';
import { useEsgStakeholderSurveyMetadata } from '../../hooks';
import { useNavigate } from 'react-router-dom';

export default function StakeholderSurveyEnd() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { metadata } = useEsgStakeholderSurveyMetadata();

  const prevLink = {
    onClick: () => navigate(`../survey`),
    label: 'esg:stakeholderSurvey.editAnswers',
  };

  return (
    <Container
      header={<img alt="" src={assets.outro.image} />}
      prevLink={prevLink}
    >
      <div>
        <Title
          title={t('esg:stakeholderSurvey.title')}
          period={metadata.report_period}
        />
        <FormattedSurvey
          text={metadata.outro_text || t('esg:stakeholderSurvey.outro.message')}
        />
      </div>
    </Container>
  );
}
