import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { client } from 'src/utils/api-client';

import { Button } from '@mui/material';
import BackButton from 'src/components/BackButton';
import { StyledTitle } from '../index.styles';
import { StyledFlex } from 'src/components/StyledFlex';
import UserForm, {
  useUserForm,
  validateForm,
} from '../components/Panels/UserForm';
import { UserInFormSchema, UserOutFormSchema } from '../index.types';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { toast } from 'react-toastify';
import { withEmptyStringsAsNull } from 'src/utils/helpers';
import { extractSchemaErrors } from '../../utils/validation';
import ConfirmationModal from 'src/components/ConfirmationModal';
import GhgUserPermissions from './Permissions/Ghg';
import EsgUserPermissions from './Permissions/Esg';
import LcaUserPermissions from './Permissions/Lca';

export default function EditUser() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formState = useUserForm();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const { data: user, isError } = useQuery(['user', id], () =>
    client
      .get<UserOutFormSchema>(`/web/settings_v2/users/${id}`)
      .then((response) => {
        const { data } = response;
        if (data) {
          formState.setFirstName(data?.first_name || '');
          formState.setLastName(data?.last_name || '');
          formState.setPosition(data?.position || '');
          formState.setEmail(data?.email || '');
          formState.setPhone(data?.phone || '');
        }
        return data;
      })
  );

  if (isError || id === undefined) return <SomethingWentWrong />;
  if (!user) return <PageLoading />;

  const handleSave = () => {
    const data: UserInFormSchema = {
      first_name: formState.firstName,
      last_name: formState.lastName,
      position: formState.position,
      email: formState.email,
      phone: formState.phone,
    };
    const formErrors = validateForm(data, false);
    formState.setErrors(formErrors);
    if (Object.keys(formErrors).length > 0) {
      return;
    }
    client
      .put(`/web/settings_v2/users/${id}`, withEmptyStringsAsNull(data))
      .then((response) => {
        toast.success(String(t('superadmin:toast.user-saved')));
        return response.data;
      })
      .catch((err) => {
        toast.error(String(t('superadmin:toast.validation-error')));
        formState.setErrors(extractSchemaErrors(err, data));
      });
  };

  const handleDelete = () =>
    client.delete(`/web/settings_v2/users/${id}`).then(() => navigate(-1));

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true);
  };

  const fullName =
    formState.firstName && formState.lastName
      ? `${formState.lastName} ${formState.firstName}`
      : formState.email;

  return (
    <>
      <StyledFlex>
        <StyledTitle>
          <BackButton navigateTo="/superadmin#users" />
          {fullName || t('superadmin:user.editUser')}
        </StyledTitle>
      </StyledFlex>

      <UserForm {...formState} id={id} onSubmit={handleSave} />

      <GhgUserPermissions sx={{ mt: '40px' }} userId={parseInt(id, 10)} />
      <EsgUserPermissions sx={{ mt: '40px' }} userId={parseInt(id, 10)} />
      <LcaUserPermissions sx={{ mt: '40px' }} userId={parseInt(id, 10)} />

      <StyledFlex sx={{ mt: '50px' }}>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleDeleteClick}
        >
          {t('superadmin:user.buttons.delete')}
        </Button>
        <Button onClick={handleSave}>
          {t('superadmin:user.buttons.save')}
        </Button>
      </StyledFlex>

      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        titleKey={t('superadmin:user.deleteConfirmation.title')}
        textKey={t('superadmin:user.deleteConfirmation.content')}
        trueButtonTextKey={t('superadmin:user.deleteConfirmation.buttonA')}
        falseButtonTextKey={t('superadmin:user.deleteConfirmation.buttonB')}
        onTrue={handleDelete}
        onFalse={() => setDeleteConfirmationOpen(false)}
      />
    </>
  );
}
