import React from 'react';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QuizIcon from '@mui/icons-material/Quiz';

import { Category, ProcessObjectPanel as Props } from './index.types';
import { StyledContainer, StyledButtonLabel } from './index.styles';
import ButtonArea from '../ButtonArea';
import CategoryButton from '../CategoryButton';
import SubcategoryModal from '../SubcategoryModal';
import LCAManagementModals from '../Modals';

import { categorySettings } from '../../helpers';
import ProcessButton from '../ProcessButton';
import { useModal } from '../Modals/Provider';
import { Subcategory } from '../../Diagram/index.types';
import { Inventory } from '@mui/icons-material';

export default function ProcessObjectPanel({ categories, productId }: Props) {
  const [categoryModal, setCategoryModal] = React.useState<
    Category | undefined
  >(undefined);

  const processHeaderText = 'ADD';
  const categoriesHeaderText = 'Add an object from one othese categories:';

  const { modalState, openModal } = useModal();

  const closeSubcategoryModal = () => setCategoryModal(undefined);

  const handleSubcategoryClick = (subcategory: Subcategory) => {
    closeSubcategoryModal();
    openModal({
      __name__: 'object',
      subcategory,
      productId,
      object: { category: categoryModal?.id, category_key: categoryModal?.key },
    });
  };

  const handleAddProcessClick = () => {
    openModal({
      __name__: 'process',
      productId,
      process: {},
    });
  };

  const handleAddSubProductClick = () => {
    openModal({
      __name__: 'sub_product',
      productId,
    });
  };

  const handleCategoryClick = (category: Category) => {
    setCategoryModal(category);
  };

  const handleAddUncategorizedObjectClick = () => {
    openModal({
      __name__: 'object',
      subcategory: null,
      productId,
      object: { category: null, category_key: null },
    });
  };

  return (
    <>
      {/* BUTTONS */}

      <StyledContainer>
        <ButtonArea header={processHeaderText}>
          <div>
            <ProcessButton
              isActive={modalState?.conf?.process}
              Icon={AccountTreeIcon}
              onClick={handleAddProcessClick}
            />
            <StyledButtonLabel>Add Process</StyledButtonLabel>
          </div>
          <div>
            <ProcessButton
              isActive={modalState?.conf?.process}
              Icon={Inventory}
              onClick={handleAddSubProductClick}
            />
            <StyledButtonLabel>Add SubProduct</StyledButtonLabel>
          </div>
        </ButtonArea>

        <div style={{ width: 20, minWidth: 20 }} />

        <ButtonArea
          header={categoriesHeaderText}
          sx={{ minWidth: 300, width: '100%' }}
        >
          <div
            style={{
              overflowY: 'scroll',
              display: 'flex',
            }}
          >
            <div>
              <CategoryButton
                categoryKey="uncategorized"
                Icon={QuizIcon}
                onClick={handleAddUncategorizedObjectClick}
              />
              <StyledButtonLabel>ANY ECOINVENT CATEGORY</StyledButtonLabel>
            </div>
            {categories?.map((category) => (
              <div key={`add-category-${category.key}-button`}>
                <CategoryButton
                  categoryKey={String(category.key)}
                  Icon={categorySettings[category.key].icon}
                  onClick={() => handleCategoryClick(category)}
                  isActive={category.key === categoryModal?.key}
                />
                <StyledButtonLabel>{category.key}</StyledButtonLabel>
              </div>
            ))}
          </div>
          {!!categoryModal && (
            <SubcategoryModal
              category={categoryModal}
              onSubcategoryClick={handleSubcategoryClick}
              onClose={closeSubcategoryModal}
            />
          )}
        </ButtonArea>
      </StyledContainer>

      {/* MODALS */}

      <LCAManagementModals />
    </>
  );
}
