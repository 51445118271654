import React from 'react';
import Breadcrumbs from 'src/Lca/components/Breadcrumbs';
import { Subtitle, Title } from 'src/Lca/components/Texts';
import LcaLayout from 'src/Lca/components/layout';
import { Button, Tab, Tabs } from '@mui/material';
import ProductInformation from '../steps/productInformation';
import { emptyProductForm, sanitizedValues } from '../utils';
import { StyledFlex } from 'src/components/StyledFlex';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { client } from 'src/utils/api-client';
import { toast } from 'react-toastify';
import { useLcaOrganization } from 'src/common/hooks';
import { StyledPanel } from 'src/Lca/styles';

const data = [
  { name: 'Products List', url: '/lca/products' },
  { name: 'New product', url: '' },
  { name: '1. Product and LCA information, goal and scope', url: '' },
];

export default function CreateLcaProductInformation() {
  const navigate = useNavigate();
  const organization = useLcaOrganization();
  const [productForm, setProductFrom] = React.useState(emptyProductForm);

  const createProduct = useMutation({
    mutationFn: () =>
      client
        .post('/web/lca/products', sanitizedValues(productForm, organization))
        .then((res) => {
          navigate(`/lca/products/${res.data.id}#goals`, { replace: true });
          toast.success('Product created successfully');
        }),
    onError: (e: any) => {
      toast.error(e.response.data.message);
    },
  });

  return (
    <LcaLayout>
      <Breadcrumbs data={data} />
      <Subtitle text="Product and LCA information, goal and scope" />
      <div style={{ marginTop: '44px', marginBottom: '40px' }}>
        <Title text="New product" />
      </div>

      <Tabs value="prod-information">
        <Tab
          key="prod-information"
          label="Product Information"
          value="prod-information"
        />
      </Tabs>

      <StyledPanel>
        <ProductInformation values={productForm} setValues={setProductFrom} />
      </StyledPanel>

      <StyledFlex sx={{ mt: '40px', mb: '80px' }}>
        <Button variant="secondary" onClick={() => navigate(-1)} type="button">
          Back
        </Button>
        <Button variant="secondary" onClick={() => createProduct.mutate()}>
          Save and continue
        </Button>
      </StyledFlex>
    </LcaLayout>
  );
}
