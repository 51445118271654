import React from 'react';
import {
  EsgDmaValueChainEntityOutSchema,
  ValueChainStakeholderTypeInSchema,
} from 'src/Esg/Dmav2/types';
import { useLanguage } from 'src/common/hooks';
import { Typography } from '@mui/material';
import ValueChainStakeholderTypesTable from './ValueChainStakeholderTypesTable';
import { getNameToColorMapping } from '../../utils';
import { useEsgStakeholderTypes } from 'src/Esg/hooks';
import { useQueries } from 'react-query';
import { getEsgDmaValueChainStakeholderTypes } from 'src/Esg/Dmav2/hooks';

interface Props {
  entitiesData: EsgDmaValueChainEntityOutSchema[];
}

interface StakeholderTypeCategories {
  name: string;
  isUpstream: boolean;
  isOwnOperations: boolean;
  isDownstream: boolean;
}

export default function ValueChainStakeholderTypesSummary({
  entitiesData,
}: Readonly<Props>) {
  const { t } = useLanguage({ keyPrefix: 'esg:dma.step1.valueChain' });
  const { dataMap: stakeholdersMap } = useEsgStakeholderTypes();

  const entityIds = entitiesData.map((entity) => entity.id);

  const stakeholderTypesResults = useQueries(
    entityIds.map((entityId) => ({
      queryKey: ['dma-value-chain-stakeholder-types', entityId],
      queryFn: () => getEsgDmaValueChainStakeholderTypes(entityId),
    }))
  );

  const stakeholderTypeMap: { [typeName: string]: StakeholderTypeCategories } =
    {};

  entitiesData.forEach((entity, index) => {
    const result = stakeholderTypesResults[index];
    if (result.data) {
      result.data.forEach(
        (entityStakeholderType: ValueChainStakeholderTypeInSchema) => {
          const stakeholder =
            stakeholdersMap[entityStakeholderType.esg_stakeholder_type_id];
          const stakeholderName = stakeholder?.name || '';
          if (!stakeholderName) return;

          if (!stakeholderTypeMap[stakeholderName]) {
            stakeholderTypeMap[stakeholderName] = {
              name: stakeholderName,
              isUpstream: false,
              isOwnOperations: false,
              isDownstream: false,
            };
          }

          if (entity.is_upstream) {
            stakeholderTypeMap[stakeholderName].isUpstream = true;
          }
          if (entity.is_own_operations) {
            stakeholderTypeMap[stakeholderName].isOwnOperations = true;
          }
          if (entity.is_downstream) {
            stakeholderTypeMap[stakeholderName].isDownstream = true;
          }
        }
      );
    }
  });

  const stakeholderTypesArray = Object.values(stakeholderTypeMap);

  const appearanceCount = (type: StakeholderTypeCategories) => {
    let count = 0;
    if (type.isUpstream) count++;
    if (type.isOwnOperations) count++;
    if (type.isDownstream) count++;
    return count;
  };

  // Sort the array based on the number of appearances (descending), then alphabetically
  stakeholderTypesArray.sort((a, b) => {
    const countA = appearanceCount(a);
    const countB = appearanceCount(b);

    if (countB !== countA) {
      return countB - countA;
    } else {
      return a.name.localeCompare(b.name);
    }
  });

  // Build rows where each row represents a stakeholder type
  const rows = stakeholderTypesArray.map((type) => ({
    upstream: type.isUpstream ? type.name : undefined,
    ownOperations: type.isOwnOperations ? type.name : undefined,
    downstream: type.isDownstream ? type.name : undefined,
  }));

  const uniqueStakeholderNames = stakeholderTypesArray.map((type) => type.name);
  const nameToColor = getNameToColorMapping(uniqueStakeholderNames);

  const renderCellContent = (
    typeName: string | undefined,
    nameToColor: { [key: string]: string }
  ) => {
    if (!typeName) return null;

    return (
      <Typography
        variant="subtitle2"
        sx={{
          backgroundColor: nameToColor[typeName] || 'transparent',
          width: 'fit-content',
          padding: '2px 8px',
          margin: '0 auto',
          borderRadius: '6px',
        }}
      >
        {typeName}
      </Typography>
    );
  };

  return (
    <ValueChainStakeholderTypesTable
      rows={rows}
      renderCellContent={renderCellContent}
      tableTitle={t('stakeholderTypes')}
      nameToColor={nameToColor}
    />
  );
}
