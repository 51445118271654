import jwtDecode from 'jwt-decode';
import { useParams } from 'react-router-dom';

interface DecodedToken {
  data?: {
    esg_stakeholder_survey_id?: number;
    // ... other optional fields
  };
}

// eslint-disable-next-line import/prefer-default-export
export function decodeTokenFromParams(): DecodedToken {
  const { token } = useParams<{ token?: string }>();
  let token_data: DecodedToken = {}; // starts as an empty object

  if (token) {
    try {
      token_data = jwtDecode<DecodedToken>(token);
    } catch (error) {
      console.error('Invalid token:', error);
    }
  }

  return token_data; // can be {} if token was not provided or decoding failed
}

export function getSurveyNumber(
  surveys: Array<{ esg_stakeholder_type_name: string }>,
  currentIndex: number
) {
  const currentSurvey = surveys[currentIndex];
  if (!currentSurvey) return 1;

  // All surveys of the same type
  const indicesForType: number[] = [];
  for (let i = 0; i < surveys.length; i++) {
    if (
      surveys[i].esg_stakeholder_type_name ===
      currentSurvey.esg_stakeholder_type_name
    ) {
      indicesForType.push(i);
    }
  }

  const rank = indicesForType.indexOf(currentIndex) + 1;
  return rank;
}
