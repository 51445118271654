import React, { useEffect } from 'react';
import {
  ProductInformationProps,
  StyledTextField,
} from '../productInformation';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { set } from 'lodash';
import Autocomplete from 'src/components/Autocomplete';
import { UploadedFileSchema } from 'src/Ghg/CodeOfConductSurvey/types';
import SingleFileUpload from 'src/components/SingleFileUpload';
import { useLcaImage } from 'src/Lca/hooks';
import { useLcaOrganization } from 'src/common/hooks';

interface LcaIndustryCode {
  code: string;
  description: string;
}

export default function BasicInfo({
  setValues,
  values,
}: ProductInformationProps) {
  const { data: cpcData } = useQuery('getCpc', () => {
    return client.get<LcaIndustryCode[]>('/web/industries/cpc');
  });

  const { data: naceData } = useQuery('getNace', () => {
    return client.get<LcaIndustryCode[]>('/web/industries/nace');
  });
  const { data: ecoinventVersions } = useQuery('getEcoinventVersions', () => {
    return client.get<string[]>('/web/lca/ecoinvent-versions');
  });
  const organization = useLcaOrganization();
  const isNewProduct = values.id === 0;

  // set default ecoinvent version to the latest
  useEffect(() => {
    if (isNewProduct && ecoinventVersions?.data) {
      setValues({
        ...values,
        ecoinvent_version: ecoinventVersions.data.at(-1),
      });
    }
  }, [ecoinventVersions, values.id, setValues]);

  const naceOptional = values.nace_industries.slice(1);

  const removeNace = (i: number) => {
    setValues({
      ...values,
      nace_industries: values.nace_industries.splice(i, 1),
    });
  };
  const removeCpc = () => {
    setValues({
      ...values,
      cpc_industry: '',
    });
  };
  const addNace = () => {
    setValues({
      ...values,
      nace_industries: [...values.nace_industries, { code: '' }],
    });
  };
  const handleNace = (value: string | null, i: number) => {
    const temp = { ...values };
    set(temp, `nace_industries[${i}].code`, value);
    setValues(temp);
  };

  const handleCpc = (value: string | null) => {
    setValues({ ...values, cpc_industry: { code: value } });
  };

  const handleName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, name: e.target.value });
  };
  const handleDesc = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, description: e.target.value });
  };
  const handleEcoinventVersion = (e: SelectChangeEvent) => {
    setValues({ ...values, ecoinvent_version: e.target.value });
  };
  const handleImageAdd = (image: UploadedFileSchema) => {
    setValues({ ...values, image_id: image.id });
  };
  const handleImageRemove = () => {
    setValues({ ...values, image_id: null });
  };
  const { image } = useLcaImage(values.image_id ?? '');

  return (
    <div
      style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <StyledTextField
        fullWidth
        label="Name of the product"
        name="name"
        value={values.name}
        onChange={handleName}
      />
      <StyledTextField
        fullWidth
        label="Description of the product"
        name="description"
        value={values.description}
        onChange={handleDesc}
        multiline
        rows={3}
      />

      <FormControl fullWidth>
        <Autocomplete
          label="Main NACE code"
          placeholder="Main NACE code"
          options={naceData?.data.map((option: LcaIndustryCode) => ({
            value: option.code,
            label: `${option.code} ${option.description}`,
          }))}
          value={values.nace_industries[0].code ?? ''}
          onChange={(value: string | null) => handleNace(value, 0)}
          sx={{
            backgroundColor:
              (values.nace_industries[0].code ?? '') ? '#F6F6F6' : 'inherit',
          }}
          handleClearClick={() =>
            setValues({
              ...values,
              nace_industries: [...values.nace_industries, { code: '' }],
            })
          }
        />
      </FormControl>

      {naceOptional.length >= 1 &&
        naceOptional.map((_, i: number) => (
          <div
            key={i}
            style={{ display: 'flex', alignItems: 'center', gap: 24 }}
          >
            <FormControl fullWidth>
              <Autocomplete
                label="Additional NACE codes (optional)"
                placeholder="Additional NACE codes (optional)"
                options={naceData?.data.map((option: LcaIndustryCode) => ({
                  value: option.code,
                  label: `${option.code} ${option.description}`,
                }))}
                value={naceOptional[i].code}
                onChange={(value: string | null) => handleNace(value, i + 1)}
                sx={{
                  backgroundColor:
                    (values.nace_industries[0].code ?? '')
                      ? '#F6F6F6'
                      : 'inherit',
                }}
                handleClearClick={() => removeNace(i)}
              />
            </FormControl>
            <Button
              variant="secondary"
              size="small"
              onClick={() => removeNace(i)}
            >
              remove
            </Button>
          </div>
        ))}
      <div>
        <Button
          variant="secondary"
          size="small"
          onClick={addNace}
          disabled={
            values.nace_industries.length > 9 ||
            values.nace_industries[values.nace_industries.length - 1]?.code ===
              ''
          }
        >
          Add another NACE code
        </Button>
      </div>

      <FormControl fullWidth>
        <Autocomplete
          label="UN CPC code"
          placeholder="UN CPC code"
          options={cpcData?.data.map((option: LcaIndustryCode) => ({
            value: option.code,
            label: `${option.code} ${option.description}`,
          }))}
          value={values.cpc_industry?.code ?? ''}
          onChange={(value: string | null) => handleCpc(value)}
          sx={{
            backgroundColor:
              (values.cpc_industry.code ?? '') ? '#F6F6F6' : 'inherit',
          }}
          handleClearClick={removeCpc}
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Ecoinvent database version</InputLabel>
        <Select
          value={values.ecoinvent_version ?? ''}
          label="Ecoinvent database version"
          onChange={handleEcoinventVersion}
          disabled={!isNewProduct}
        >
          {ecoinventVersions?.data.map((version) => (
            <MenuItem key={version} value={version}>
              {version}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {image && (
        <Box
          component="img"
          src={image.url}
          sx={{
            objectFit: 'contain',
            maxWidth: '400px',
            maxHeight: '400px',
          }}
        />
      )}
      <SingleFileUpload
        url={`/web/organizations/${organization.id}/images`}
        attachButtonText="Upload product image"
        onSuccess={handleImageAdd}
        onRemoved={handleImageRemove}
        file={image}
      />
    </div>
  );
}
