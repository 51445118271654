import React from 'react';

import ProcessModal from './Process';
import ObjectModal from './Object';
import { useModal } from './Provider';
import SubProductModal from './SubProduct';

const getComponent = (modalName: string) => {
  switch (modalName) {
    case 'object':
      return ObjectModal;
    case 'process':
      return ProcessModal;
    case 'sub_product':
      return SubProductModal;
    default:
      return () => <div />;
  }
};
export default function Modals() {
  const { modalState, closeModal } = useModal();

  const Component = getComponent(modalState?.conf?.__name__);
  const componentProps = modalState?.conf || {};

  return (
    <Component
      {...componentProps}
      onClose={closeModal}
      open={!!modalState?.open}
    />
  );
}
