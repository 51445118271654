import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  InitialRequestSchema,
  PlatformError,
  PlatformState,
} from './slice.types';
import { SidebarState } from '../../views/components/Sidebar/index.types';
import { OrganizationDataOutSchema } from 'src/utils/api.interfaces';

const initialState: PlatformState = {
  isLoading: false,
  isAuthenticated: undefined,
  error: null,
  sidebarState: {
    isControllable: true,
    isOpen: true,
    isShown: true, // default is set by current Layout
  },
  user: null,
  organizations: null,
  availableProducts: [],
  allowAccessToManageOrgStructure: false,
  activeOrganizationId:
    parseInt(localStorage.getItem('activeOrganizationId') || '', 10) || null,
  activeOrganization: null,
  platformSubscriber: { name: '', maxNumOrganizations: 0 },
  tags: [],
};

const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      return { ...state, isLoading: action.payload };
    },
    setError: (state, action: PayloadAction<PlatformError>) => {
      return { ...state, error: action.payload };
    },
    setInitialRequest: (state, action: PayloadAction<InitialRequestSchema>) => {
      state.user = {
        id: action.payload.id,
        email: action.payload.email,
        isSuperUser: action.payload.is_super_user,
        ecoinventProLicenceIsValid:
          action.payload.is_ecoinvent_pro_licence_valid,
        isPasswordSet: action.payload.is_password_set,
        demoAccessValidUntil: action.payload.demo_access_valid_until,
      };
      state.organizations = action.payload.organizations;
      state.availableProducts = action.payload.available_products;
      state.allowAccessToManageOrgStructure =
        action.payload.allow_access_to_manage_org_structure;

      state.platformSubscriber = {
        name: action.payload.platform_subscriber.name,
        maxNumOrganizations:
          action.payload.platform_subscriber.max_num_organizations,
      };
      state.tags = action.payload.tags;
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setActiveOrganizationId: (state, action: PayloadAction<number | null>) => {
      // TODO: this could just be persisted via redux
      if (action.payload)
        localStorage.setItem('activeOrganizationId', String(action.payload));
      else localStorage.removeItem('activeOrganizationId');
      state.activeOrganizationId = action.payload;
    },
    setActiveOrganization: (
      state,
      action: PayloadAction<OrganizationDataOutSchema | null>
    ) => {
      state.activeOrganization = action.payload;
    },
    updateSidebarState: (
      state,
      action: PayloadAction<Partial<SidebarState>>
    ) => {
      Object.assign(state.sidebarState, action.payload);
    },
  },
});

export const {
  setLoading,
  setError,
  setInitialRequest,
  setIsAuthenticated,
  setActiveOrganizationId,
  setActiveOrganization,
  updateSidebarState,
} = platformSlice.actions;

export default platformSlice.reducer;
